import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { FaChevronRight } from 'react-icons/fa';
import MarkdownRender from '../components/markdown-render';
import Layout from '../components/layout';
import ContentContainerGrid from '../components/content-container-grid';
import Article from '../components/article';
import Testimonial from '../components/testimonial';
import ImageBanner from '../components/full-width-image-banner';
import Metadata from '../components/metadata';

export const CaseStudyTemplate = ({
  imageWidth, image, imageBg, title, client, testimonials, body,
}) => (
  <ContentContainerGrid sx={{ mb: 5 }}>
    {image && (
      <ImageBanner
        width={imageWidth}
        src={image}
        bg={imageBg}
        alt={title}
      />
    )}
    <Article>
      <Styled.h1 sx={{ color: '#999', m: 0 }}>{client}</Styled.h1>
      <Styled.h2 sx={{ mb: 4 }}>{title}</Styled.h2>
      {body && <MarkdownRender md={body} />}
      {testimonials && testimonials.map((testimonial) => (
        <Testimonial key={testimonial.quote} {...testimonial} />
      ))}
      <Styled.a href="/work">
        Explore the rest of our cases
        <FaChevronRight sx={{ position: 'relative', top: '3px' }} />
      </Styled.a>
    </Article>
  </ContentContainerGrid>
);

CaseStudyTemplate.propTypes = {
  imageWidth: PropTypes.number,
  imageBg: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }),
  ]),
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  testimonials: PropTypes.array,
  body: PropTypes.string,
};

CaseStudyTemplate.defaultProps = {
  imageBg: null,
  image: null,
  imageWidth: null,
  body: null,
  testimonials: [],
};

const CaseStudyPage = ({ data }) => {
  const { mdx: page } = data;
  const { frontmatter: metadata, body } = page;

  return (
    <Layout>
      <Metadata pageData={metadata} />
      <CaseStudyTemplate
        imageWidth={metadata.featuredImage.width}
        image={metadata.featuredImage.image}
        imageBg={metadata.featuredImage.bgcolor}
        title={metadata.title}
        client={metadata.client}
        testimonials={metadata.testimonials}
        body={body}
      />
    </Layout>
  );
};

CaseStudyPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }).isRequired,
};

export default CaseStudyPage;

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        description
        browsertitle
        client
        featuredImage {
          image {
            publicURL
          }
          width
          bgcolor
        }
        testimonials {
          name
          title
          organization
          quote
        }
      }
    }
  }
`;
