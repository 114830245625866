/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Img from './image';

const FullWidthImageBanner = ({
  width, src, alt, bg,
}) => (
  <header
    sx={{
      gridColumn: '1 / -1',
      height: '200px',
      display: 'flex',
      mb: 3,
      justifyContent: 'center',
      alignItems: 'center',
      bg: bg || 'clientbg',
    }}
  >
    <Img
      width={width}
      src={src}
      alt={alt}
    />
  </header>
);

FullWidthImageBanner.propTypes = {
  width: PropTypes.number.isRequired,
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  bg: PropTypes.string,
};

FullWidthImageBanner.defaultProps = {
  bg: null,
};

export default FullWidthImageBanner;
